.modal__component {
  height: 100vh;
  width: 100vw;
  position: absolute;
  inset: 0 0 0 auto;
  overflow: hidden;
  &-dates{
    width: 450px;
    .ant-popover-inner-content{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .ant-modal-body{
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .modal__component-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
        color: $primary;
      }
      &-iconsWrapper {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  } 
  .ant-modal-content {
    min-height: 100vh;
    max-height: 100vh;
    .modal__component-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-iconsWrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        & > * {
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
      .title {
        font-family: 'Montserrat';
        font-size: 18px;
        font-weight: 600;
        color: $primary;
      }
      .lead__id {
        display: flex;
        gap: 5px;
        color: #252525;
        font-family: 'Montserrat';
        font-size: 14px;
        .lead__icon {
          svg {
            & > * {
              fill: $primary;
            }
          }
        }
        .lead__text {
          font-weight: 600;
        }
        &__value {
          font-weight: 800;
        }
      }
    }
    .ant-modal-body {
      padding: 30px;
    }
  }
  &-content {
    .form__wrapper {
      min-height: calc(100vh - 50px);
      max-height: calc(100vh - 50px);
      overflow: hidden;
      padding: 20px 0;
      position: relative;
      &-actions {
        position: absolute;
        inset: auto 0 30px 0;
        .primaryButton {
          width: 100%;
          background-color: $primary;
          height: 45px;
          border-radius: 8px;
          font-family: 'Montserrat';
          font-size: 14px;
          font-weight: 600;
          color: #fff;
        }
      }
      &-inputs {
        max-height: calc(100vh - 160px);
        padding-bottom: 20px;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        overflow: auto;
        overflow-x: hidden;
        &.gapLess__inputs {
          gap: 0px;
          justify-content: space-between;
          & > * {
            margin-bottom: 20px;
          }
        }
        .form-element-row {
          display: flex;
          align-items: center;
          gap: 20px;
          flex-wrap: wrap;
          label {
            padding-right: 0px;
          }
        }
        .create-status-modal-form-subCategory {
          width: 100%;
          margin-top: 0px;
          height: calc(100vh - 440px);
        }
        .range__picker-wrapper {
          width: 100%;
          &-container {
            .ant-picker {
              width: 50%;
            }
          }
        }
        
        .custom-render-wrapper{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .selected__teamMember {
            font-family: 'Montserrat';
            margin-bottom: 5px;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            min-height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 11px;
            height: fit-content;
            .name-wrapper{
              display: flex;
              flex-direction: column;
            }
            .name{
              color: #979797;
              font-size: 14px;
              font-weight: 600;
            }
            .date{
              font-family: 'Montserrat';
              color: #979797;
              font-size: 12px;
              font-weight: 500;
            }
            .role{
              display: flex;
              align-items: center;
              gap: 10px;
              color: #979797;
              &-head{
                color: #31B073;
                font-size: 13px;
                font-weight: 600;
              }
              &-name{
                font-size: 12px;
                font-weight: 600;
              }
              &-member{
                font-size: 12px;
                font-weight: 500;
                img{
                  width: 12px;
                  height: 12px;
                  margin-right: 2px;
                }
              }
            }
            .menu-icon{
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px 0px 10px 10px;
            }
          }
        }
        .form_input {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 0px;
          input {
            margin: 0px;
            border: 1px solid #d9d9d9;
            height: 40px;
            padding: 0 20px;
            &:disabled {
              background-color: #f5f5f5 !important;
            }
          }
          .errorClass {
            // max-width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }
        }
        .MuiFormControlLabel-root {
          margin-left: 0px;
          font-family: 'Montserrat';
          font-size: 14px;
          font-weight: 600;
          color: #252525;
          display: flex;
          align-items: center;
          gap: 5px;
        }
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          cursor: pointer;
          background: $primary;
          border-radius: 10px;
        }
        .textarea_container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          height: auto;
          &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .textarea__input {
            display: flex;
            flex-direction: column;
            width: 60%;
            font-family: 'Montserrat';
            textarea {
              width: 100%;
              border: 1px solid #d9d9d9;
              border-radius: 5px;
              outline: none;
              margin-top: 5px;
              resize: none;
              height: 120px;
              padding: 10px 20px;
              color: #252525;
            }
            .textarea_max_length {
              color: #ddd;
              font-size: 14px;
              align-self: flex-end;
              margin: 5px 0;
            }
            .form_input__label {
              .info__icon {
                margin-left: 5px;
                cursor: pointer;
                position: relative; 
                top: -3px;
              }
            }
          }
        }
        .color-picker-with-popover {
          width: 100%;
          display: flex;
          flex-direction: column;
          .colorPicker__label {
            font-family: 'Montserrat';
            font-size: 12px;
            font-weight: 600;
            color: #252525;
          }
          &__input {
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            height: 40px;
            margin-top: 5px;
          }
          .errorClass {
            display: block;
            margin-top: 5px;
            margin-left: 5px;
            color: #d00a12;
            font-family: 'Montserrat';
            font-size: 10px;
            font-style: italic;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      .preview-inputs__wrapper {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        padding: 10px 20px;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        .single-input {
          padding: 7px 0;
          border-bottom: 1px solid #E0E0E0;
          display: flex;
          align-items: center;
          &.borderLess {
            border-color: transparent;
          }
          span {
            font-family: "Montserrat";
            font-size: 14px;
            font-weight: 600;
          }
          .title {
            color: #979797;
            width: 30%;
          }
          .value {
            color: #252525;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            max-width: 70%;
          }
        }
      }
    }
  }
  .modal__activity {
    width: 100%;
    .modal__activity__header {
      font-family: 'Montserrat';
      padding: 15px 5px 5px 5px;
      p {
        color: #252525;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .comment_wrapper {
      margin: 10px 0;
      display: flex;
      align-items: center;
      .user_avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #a797c7;
        align-self: flex-start;
        margin-top: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
      }
      .comment__container {
        margin-left: 13px;
        width: 90%;
        display: flex;
        flex-direction: column;
        .comment_info {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          font-family: 'Montserrat';
          padding: 0 7px;
          .comment_user {
            font-size: 13px;
            font-weight: 600;
            color: #252525;
          }
          .comment_time {
            color: #8a8a8a;
            font-size: 10px;
            font-weight: 500;
          }
        }
        .comment {
          font-family: 'Montserrat';
          margin: 5px 0;
          background: #f0f0f0;
          border-radius: 10px;
          padding: 5px 8px;
          &_title {
            color: #484848 !important;
            font-size: 13px;
          }
        }
      }
    }
  }
  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  &-empty {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    span {
      font-family: "Montserrat";
      color: #252525;
      font-weight: 600;
      font-size: 18px;
    }
  }
}
